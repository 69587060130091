const sourcesDev = [
    {
        url: `/api/info/calendar`,
        timer: 1 * 60,
        target: 'opscal'
    },
    {
        url: `/api/info/calendar?calendarid=events`,
        timer: 1 * 60,
        target: 'eventscal'
    },
    {
        url: `/api/info/wx`,
        timer: 5 * 60,
        target: 'wx'
    },
    {
        url: `https://test1.priv.52west.net/api/extnotices`,
        timer: 1 * 60,
        target: 'notices'
    },
]

const sourcesProd = [
    {
        url: `https://members.countryglen.org/api/info/calendar`,
        timer: 30 * 60,
        target: 'opscal'
    },
    {
        url: `https://members.countryglen.org/api/info/calendar?calendarid=events`,
        timer: 10 * 60,
        target: 'eventscal'
    },
    {
        url: `https://members.countryglen.org/api/info/wx`,
        timer: 5 * 60,
        target: 'wx'
    },
    {
        url: `https://members.countryglen.org/api/extnotices`,
        timer: 1 * 60,
        target: 'notices'
    },
]

export default process.env.NODE_ENV === 'production' ? sourcesProd : sourcesDev