import { of, interval, concat, Subject, BehaviorSubject, Observable, merge } from 'rxjs';
import { take, map, flatMap, catchError, concatAll, concatMap, filter } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import sources from './ApiSources';

var state:any = {};
const apiData$ = new BehaviorSubject(null as any);

var triggerRefresh: any;

let refresher = new Observable(subscriber => {
    triggerRefresh = () => {
        state = {};
        subscriber.next();
    };
});

let refreshSubject = new Subject();
refresher.subscribe(x => refreshSubject.next());


let mappedSources = sources.map(s => 
    merge( of(0), interval(s.timer * 1000), refreshSubject).pipe(
        flatMap(i => ajax.getJSON(s.url).pipe(
            catchError(error => {
                console.log('error: ', error);
                return of(null);
            })
        )),
        filter( x => x !== null),
        map(x => ({[s.target]: x}))
    )
);

merge(...mappedSources).subscribe(d => {
    state = Object.assign({}, state, d);
    if(Object.keys(state).length === sources.length) {
        apiData$.next(state);
    }
})

apiData$.subscribe(x => console.log('calendar$', x))

export { apiData$, triggerRefresh }

