import React from 'react';
import { IonRefresher, IonRefresherContent, IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import { RefresherEventDetail } from '@ionic/core';
import moment from 'moment';
import { Notices, OperatingHours, WxConditions, Events } from '../components/AppComponents';
import './Home.css';
import { apiData$, triggerRefresh } from '../services/services';

function doRefresh(event: CustomEvent<RefresherEventDetail>) {
  console.log('Begin async operation');

  triggerRefresh(() => {
    console.log('refresh complete');
    event.detail.complete();
  });
  //event.detail.complete();
}


const Home: React.FC = () => {

  const [apiDataState, setApiDataState] = React.useState({} as any);
  const [refreshState, setRefreshState] = React.useState(null as any);

  const doRefresh2 = (event: CustomEvent<RefresherEventDetail>) => {
    triggerRefresh();
    setRefreshState(event);
  }

  React.useEffect(() => {
    const subscription = apiData$.subscribe( data => {
      if(refreshState) {
        console.log('refreshState is', refreshState)
        refreshState.detail.complete();
        setRefreshState(null);
      }
      setApiDataState(data);
    });
    return () => subscription.unsubscribe();
  });

  let day = moment().format('YYYY-MM-DD');
  return (

    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle size="large">Today</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>

        <IonRefresher slot="fixed" onIonRefresh={doRefresh2 }>
          <IonRefresherContent

            pullingText="Pull to refresh"
            refreshingSpinner="circles"
            refreshingText="Refreshing...">

          </IonRefresherContent>
        </IonRefresher>
        {apiDataState && apiDataState.notices &&
          <Notices notices={apiDataState.notices} />
        }

        <OperatingHours hours={apiDataState && apiDataState.opscal && apiDataState.opscal.filter((e: any) => e.day === day)} />

        {apiDataState && apiDataState.wx &&
          <WxConditions {...apiDataState.wx.conditions} />
        }

        {apiDataState && apiDataState.eventscal &&
          <Events events={apiDataState.eventscal.filter((e: any) => e.day === day)} />
        }


      </IonContent>
    </IonPage>

  );
};

export default Home;
