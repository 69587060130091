import React from 'react';
import moment from 'moment';
import './AppComponents.css';

export interface INotice {
  priority: number,
  collapseKey: string,
  text: string,
  title: string,
}

interface INoticesProps {
  notices: Array<INotice>;
}

const Notices: React.FC<INoticesProps> = ({ notices }) => {
  return (
    <div >
      {notices.map((n, i) => {
        return (
          <div key={i}>
            {n.text}
          </div>
        )
      })}
    </div>
  );
};

export interface IOperatingHours {
  hours: [{
    start: string,
    end: string
  }]
}

const OperatingHours: React.FC<IOperatingHours> = ({ hours }) => {
  if (hours) {
    return (
      <div>
        <span>Operating Hours:</span>

        {hours.map((h, i) =>
          <span key={i}>{moment(h.start).format('h:mm a')} - {moment(h.end).format('h:mm a')}</span>
        )
        }
      </div>
    );
  } else {
    return (
      <div>Closed</div>
    )
  }
};


interface WxConditionsProps {
  temperature: number;
  humidity: number;
  uvi: number;
  description: string;
  icon: string;
}

const WxConditions: React.FC<WxConditionsProps> = ({ temperature, humidity, uvi, description, icon }) => {
  return (
    <div>
      {Math.round(temperature)}
      <i className={'wu wu-black wu-32 wu-' + iconCodeMap[icon]} />
      {description}
    </div>
  );
};


interface IEvent {
  start: string,
  end: string,
  day: string,
  title: string,
  description: string
}

interface IEventProps {
  events: Array<IEvent>;
}

const Events: React.FC<IEventProps> = ({ events }) => {
  return (
    <div>
      Events
      {events.map((e, i) =>
        <div key={i}>
          {moment(e.start).format('h:mm a')} - {moment(e.end).format('h:mm a')}
          {e.title}
          {e.description}
        </div>
      )

      }
    </div>
  );
};


type tIconMap = {
  [key: string]: string
}

const iconCodeMap: tIconMap = {
  '11d': 'tstorms',
  '09d': 'rain',
  '10d': 'rain',
  '13d': 'snow',
  '50d': 'fog',
  '01d': 'clear',
  '01n': 'clear',
  '02n': 'mostlysunny',
  '02d': 'mostlysunny',
  '03d': 'mostlysunny',
  '03n': 'mostlysunny',
  '04d': 'mostlycloudy',
  '04n': 'mostlycloudy',
}
export { Notices, OperatingHours, WxConditions, Events, iconCodeMap }


/*
<Notices notices={{}} />
  <OperatingHours hours={{}} />
  <CurrentWX currentWXdata={{}} />
  <Events events={{}} />
*/