import React, { Component } from 'react';
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import { IonList, IonItem, IonLabel, IonListHeader, IonThumbnail, IonInput, IonToggle, IonRadio, IonCheckbox, IonItemSliding, IonItemOption, IonItemOptions } from '@ionic/react';
import { OperatingHours, WxConditions } from '../components/AppComponents';
import { apiData$, triggerRefresh } from '../services/services';

import './Calendar.css';
import 'weather-underground-icons/dist/wu-icons-style.min.css';
import { iconCodeMap } from '../components/AppComponents'
import moment from 'moment';

const Calendar: React.FC = () => {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle size="large">Hours &amp; Weather</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <HoursAndWeather />
      </IonContent>
    </IonPage>
  );
};


type WxConditions = {
  temperature: number;
  humidity: number;
  uvi: number;
  description: string;
  icon: string;
}

type DailyOperatingHours = {
  start: string,
  end: string
}

type State = Partial<{
  dataEpoch: string,
  currentWxConditions: WxConditions,
  forecast: WxConditions[],
  operatingHours: DailyOperatingHours[]
}>

const HoursAndWeather: React.FC = () => {

  const [apiDataState, setApiDataState] = React.useState({} as any);

  React.useEffect(() => {
    const subscription = apiData$.subscribe(setApiDataState);
    return () => subscription.unsubscribe();
  }, []);

  return (

    <IonList>
      {apiDataState && apiDataState.wx && apiDataState.wx.forecast.map((d: any, i: number) =>
        <IonItem key={i}>
          <IonThumbnail slot="end">
            <i className={'wu wu-black wu-32 wu-' + iconCodeMap[d.icon]} />
            {d.temperature}
          </IonThumbnail>
          <IonLabel>
            <h2>{moment(apiDataState.wx.epoch).add(i, 'day').format('ddd')}</h2>
            <OperatingHours hours={apiDataState.opscal.filter((e: any) => e.day === moment(apiDataState.wx.epoch).add(i, 'day').format('YYYY-MM-DD'))} />
            {/*
                  {moment(apiDataState.wx.operatingHours![i].start).format('h:mm a')} - {moment(apiDataState.wx..operatingHours![i].end).format('h:mm a')}
                  */}
          </IonLabel>
          {/*

                <WeatherConditions icon={d.icon} description={d.description} />&nbsp;
                  High: {d.temperature}
                */}
        </IonItem>
      )
      }
    </IonList>

  )
}


export default Calendar;